import {request} from '@/utils/request'

// 焦点图
export function getBanner(data) {
  return request('GET','/focus', data, true, true)
}

// 楼层
export function getFloor(data) {
  return request('GET','/floor', data, true, true)
}



