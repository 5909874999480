<template>
    <div class="shop_main">
        <div class="page_index_banner">
            <div class="banner">
                <el-carousel trigger="click" height="370px">
                    <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                        <el-image :src="imageURL + item.url" class="image" fit="contain" @click="goUrl(item.type, item.info)"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>

           <!-- <div class="notice">
                <h1 class="title">商城公告</h1>
                <ul>
                    <li><a href="">【公告】优质商户招募中！</a></li>
                    <li><a href="">【公告】新一批货源已到，便宜！</a></li>
                    <li><a href="">【公告】部分商户违规经营通告！</a></li>
                    <li><a href="">【公告】市场行情分析报告！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                    <li><a href="">【公告】平台上线供应链金融服务！</a></li>
                </ul>
            </div>-->
        </div>

        <floor :floorList="floorList" ref="floor" />

        <div class="index_title" ref="hot">热门推荐</div>
        <div class="shop_list_goods" v-loading="isRequestLoading">
            <div class="content">
                <goodsItem v-for="(item, index) in listData" :key="index" :item="item" />
            </div>
        </div>

        <div class="popup_back">
            <div class="list">
                <template v-for="(item, i) in floorList">
                    <a :key="i" href="javascript:void(0)" v-if="JSON.parse(item.textArea).header" @click="goTop('#floor' + item.id)">{{JSON.parse(item.textArea).header.title}}</a>
<!--                    :href="'#floor' + item.id"-->
                </template>
                <a href="javascript:void(0)" @click="goTopHot">热门推荐</a>
            </div>

            <a href="javascript:void(0)" class="back" @click="goBack" v-if="show">
                <img src="../../../assets/img/back.png" />
                <span>顶部</span>
            </a>
        </div>
    </div>
</template>

<script>
  import {getBanner, getFloor} from '../../../api/shop/public'
  import {imageURL} from "../../../utils/config";
  import {getGoodsList} from "../../../api/shop/goods";
  const goodsItem = () => import('../../../components/layout/shop/goodsItem')
  const floor = () => import('../../../components/layout/shop/floor')
  export default {
    name: "index",
    data () {
      return {
        bannerList: [],
        imageURL: imageURL,
        isRequestLoading: false,
        listData: [],
        floorList: [],
        scrollTop: null,
        show: false
      }
    },
    created() {
      this.getBanner()
      this.getFloor()
      this.getList()
    },
    mounted () {
      window.addEventListener('scroll', this.scrollToTop)
    },
    destroyed () {
      window.removeEventListener('scroll', this.scrollToTop)
    },
    methods: {
      getBanner () {
        getBanner().then(res => {
          if (res.code === 0) {
            this.bannerList = res.data
          }
        })
      },
      getFloor () {
        getFloor().then(res => {
          if (res.code === 0) {
            this.floorList = res.data
          }
        })
      },
      goUrl (type, info) {
        if (type === 1) {
          window.open(info)
        }
        if (type === 2) {
          this.$router.push({path: '/shop/detail', query: {id: info}})
        }
      },
      getList () {
        this.isRequestLoading = true
        getGoodsList({
          page: 1,
          pageSize: 10,
          type: 0,
          desc: 1,
          channel: 1
        }).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.listData = res.data.records
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      goBack () {
        this.toScrollTop(0, 200)
      },
      scrollToTop () {
        let scrollTop = document.documentElement.scrollTop || window.pageYOffset ||  document.body.scrollTop;
        this.scrollTop = scrollTop
        if (scrollTop > 541) {
          this.show = true;
        } else {
          this.show = false;
        }
      },
      goTop (id) {
        let scrollTop = this.$refs.floor.$refs[id][0].offsetTop - 40
        this.toScrollTop(scrollTop, 200)
      },
      goTopHot () {
        let scrollTop = this.$refs.hot.offsetTop - 40
        this.toScrollTop(scrollTop, 200)
      },
      toScrollTop (number = 0, time) {
        if (!time) {
          document.body.scrollTop = document.documentElement.scrollTop = number;
          return number;
        }
        const spacingTime = 20;
        let spacingIndex = time / spacingTime;
        let nowTop = document.body.scrollTop + document.documentElement.scrollTop;
        let everTop = (number - nowTop) / spacingIndex;
        let that = this
        let scrollTimer = setInterval(() => {
          if (spacingIndex > 0) {
            spacingIndex--;
            that.toScrollTop(nowTop += everTop);
          } else {
            clearInterval(scrollTimer);
          }
        }, spacingTime);
      }
    },
    components: {
      goodsItem, floor
    }
  }
</script>
